import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './pages.css';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {Home} from '../components/views';

const IndexPage = () => (
  <Layout>
    <SEO title="Bodhi" />
    <Home />
  </Layout>
);

export default IndexPage;
